/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// DataSource import_map add pair button action
// removeImportMapPair() at the end assignings remove button
// for newly added pairs

const addMoreImportMap = function() {
  $('#add-more-import-map').off('click.import_map');
  return $('#add-more-import-map').on('click.import_map', function(e) {
    const $container = $('#import-map-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    $dataIndex = parseInt($dataIndex) + 1;
    const $options = ["agent_name", "brokerage_name", "first_name", "middle_name", "last_name", "suffix_name", "brokerage_office_name", "street_address", "street_address_2", "city", "state", "postal_code", "telephone_work", "telephone_mobile", "email_work", "license_state", "license_number", "license_type", "license_status", "license_number_for_broker", "license_expires_on", "license_issued_on", "supervisor_license_number", "web_home_page"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    const $formInput2 = `<div class="row form-group"> \
                         <div class="col-sm-1"> \
                         <a class="btn btn-xs btn-default remove-pair-import-map" data-index=` + $dataIndex + ` >Remove</a> \
                         </div> \
                         <div class="col-sm-5"> \
                         <select class="form-control" name="data_source[import_map][[` + $dataIndex + '][key]]"' + ' id="data_source_import_map[' + $dataIndex + '][key]" placeholder="file header field name" class="form-control" data-index=' + $dataIndex + ` ">\
                         ` + $string_options + `\
                         </select> \
                         </div> \
                         <div class="col-sm-5"> \
                         <input type="text" name="data_source[import_map][` + $dataIndex + '][value]"' + ' id="data_source_import_map_' + $dataIndex + '_value" placeholder="file header field name" value="" class="form-control" data-index=' + $dataIndex + ` "> \
                         </div> \
                         </div>`;
    $('#import-map-fields').append($formInput2);
    return removeImportMapPair();
  });
};

// DataSource import_map remove pair button action
var removeImportMapPair = function() {
  $('.remove-pair-import-map').off('click.import_map');
  return $('.remove-pair-import-map').on('click.import_map', function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    $(this).closest('.form-group').find(':input').each(function() {
      return $(this).val('');
    });
    return $this.closest('.form-group').remove();
  });
};

const InitImportMap = function() {
  addMoreImportMap();
  return removeImportMapPair();
};

// DataSource assertion add pair button action
// removeAssertionPair() at the end assignings remove button
// for newly added pairs
const addMoreAssertionPair = function() {
  $('#add-more-assertion').off('click.assertion');
  return $('#add-more-assertion').on('click.assertion', function(e) {
    const $container = $('#assertion-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    $dataIndex = parseInt($dataIndex) + 1;
    const $formInput = "<div class=\"row form-group\"><div class=\"col-sm-1\"><a class=\"btn btn-xs btn-default remove-pair-assertion\" data-index=" + $dataIndex + ' >' + 'Remove' + '</a>' + '</div>' + '<div class="col-sm-5">' + '<input type="text" name="data_file[data_source_attributes][assertion][' + $dataIndex + '][key]"' + ' id="data_file_data_source_attributes_assertion_' + $dataIndex + '_value" value="" class="form-control"' + 'data-index=' + $dataIndex + ' ">' + '</div>' + '<div class="col-sm-5">' + '<input type="text" name="data_file[data_source_attributes][assertion][' + $dataIndex + '][value]"' + ' id="data_file_data_source_attributes_assertion_' + $dataIndex + '_value" value="" class="form-control"' + 'data-index=' + $dataIndex + ' ">' + '</div>' + '</div>';
    $('#assertion-fields').append($formInput);
    return removeAssertionPair();
  });
};

const addMoreAssertionPairToDataSourceForm = function() {
  $('#add-more-assertion-to-data-source').off('click.assertion');
  return $('#add-more-assertion-to-data-source').on('click.assertion', function(e) {
    const $container = $('#assertion-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    $dataIndex = parseInt($dataIndex) + 1;
    const $formInput = "<div class=\"row form-group\"><div class=\"col-sm-1\"><a class=\"btn btn-xs btn-default remove-pair-assertion\" data-index=" + $dataIndex + ' >' + 'Remove' + '</a>' + '</div>' + '<div class="col-sm-5">' + '<input type="text" name="data_source[assertion][[' + $dataIndex + '][key]]"' + ' id="data_source_assertion_[' + $dataIndex + '][key]" value="" class="form-control"' + 'data-index=' + $dataIndex + ' ">' + '</div>' + '<div class="col-sm-5">' + '<input type="text" name="data_source[assertion][' + $dataIndex + '][value]"' + ' id="data_source_assertion_[' + $dataIndex + '][value]" value="" class="form-control"' + 'data-index=' + $dataIndex + ' ">' + '</div>' + '</div>';
    $('#assertion-fields').append($formInput);
    return removeAssertionPair();
  });
};

// DataSource assertion remove pair button action
var removeAssertionPair = function() {
  $('.remove-pair-assertion').off('click.assertion');
  return $('.remove-pair-assertion').on('click.assertion', function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    $(this).closest('.form-group').find(':input').each(function() {
      return $(this).val('');
    });
    return $this.closest('.form-group').hide();
  });
};

const InitAssertion = function() {
  addMoreAssertionPair();
  addMoreAssertionPairToDataSourceForm();
  return removeAssertionPair();
};


const addMoreDefaultFields = function() {
  $('#add-more-default-fields-to-data-source').off('click.config');
  return $('#add-more-default-fields-to-data-source').on('click.config', function(e) {
    const $container = $('#default-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index') || 0;
    $dataIndex = parseInt($dataIndex) + 1;
    const $options = ["agent_name", "brokerage_name", "first_name", "middle_name", "last_name", "suffix_name", "brokerage_office_name", "street_address", "street_address_2", "city", "state", "postal_code", "telephone_work", "telephone_mobile", "email_work", "license_state", "license_number", "license_type", "license_status", "license_number_for_broker", "license_expires_on", "license_issued_on", "supervisor_license_number", "web_home_page"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    const $formInput = `<div class="row form-group"> \
                        <div class="col-sm-1"> \
                        <a class="btn btn-xs btn-default remove-pair-default-fields" data-index=` + $dataIndex + ` >Remove</a> \
                        </div> \
                        <div class="col-sm-5"> \
                        <select class="form-control" name="data_source[default_fields][[` + $dataIndex + '][key]]"' + ' id="data_source_default_fields[' + $dataIndex + '][key]" placeholder="file header field name" class="form-control" data-index=' + $dataIndex + ` ">\
                        ` + $string_options + `\
                        </select> \
                        </div> \
                        <div class="col-sm-5"> \
                        <input type="text" name="data_source[default_fields][` + $dataIndex + '][value]"' + ' id="data_source_default_fields_' + $dataIndex + '_value" placeholder="Default field value" value="" class="form-control" data-index=' + $dataIndex + ` "> \
                        </div> \
                        </div>`;
    $('#default-fields').append($formInput);
    return removeDefaultFields();
  });
};

var removeDefaultFields = function() {
  $('.remove-pair-default-fields').off('click.config');
  return $('.remove-pair-default-fields').on('click.config', function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    $(this).closest('.form-group').find(':input').each(function() {
      return $(this).val('');
    });
    return $this.closest('.form-group').remove();
  });
};

const RemoveAttributeDisabled = function() {
  if ($('.remove-pair-data-patch').length > 1) {
    return $('.remove-pair-data-patch').removeAttr('disabled');
  }
};

const InitDefaultFields = function() {
  addMoreDefaultFields();
  return removeDefaultFields();
};

const addMoreDataPatchPairs = function() {
  $('#add-more-data-patches').off('click.config.data_patch');
  return $('#add-more-data-patches').on('click.config.data_patch', function(e) {
    let $inputId, $inputName;
    const $container = $('#data-patch-fields');
    const $form = $container.find('.form-control');
    if ($form[0].name.match(/data_source_attributes/)) {
      $inputName = "data_file[data_source_attributes]";
      $inputId = "data_file_data_source_attributes";
    } else {
      $inputName = "data_source";
      $inputId = "data_source";
    }
    let $dataIndex = $form.last().attr('data-index');
    $dataIndex = parseInt($dataIndex) + 1;
    const $formInput = `<div class='row form-group'> \
                        <div class='col-sm-1'> \
                        <a class='btn btn-xs btn-default remove-pair-data-patch' data-index='` + $dataIndex + `'> \
                        Remove \
                        </a> \
                        </div> \
                        <div class='col-sm-5'> \
                        <input class='form-control' data-index='` + $dataIndex + "' value='' type='text' name='" + $inputName + "[config][data_patch][[" + $dataIndex + "][0]]' id='" + $inputId + `_config_data_patch_[0][0]'> \
                        </div> \
                        <div class='col-sm-6'> \
                        <input class='form-control' data-index='` + $dataIndex + "' value='' type='text' name='" + $inputName + "[config][data_patch][[" + $dataIndex + "][1]]' id='" + $inputId + `_config_data_patch_[0][1]'> \
                        </div> \
                        </div>`;
    $('#data-patch-fields').append($formInput);
    RemoveAttributeDisabled();

    return removeDataPatchPairs();
  });
};

// DataSource data_patch remove pair button action
var removeDataPatchPairs = function() {
  $('.remove-pair-data-patch').off('click.config.data_patch');
  return $('.remove-pair-data-patch').on('click.config.data_patch', function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    if (($dataIndex !== 0) && ($this.attr('disabled') !== 'disabled')) {
      $('input[data-index=' + $dataIndex + ']').each((index, elem) => elem.value = '');
      $this.closest('.form-group').remove();
    }

    if ($('.remove-pair-data-patch').length <= 1) {
      return $('.remove-pair-data-patch').first().attr('disabled',true);
    }
  });
};

const InitDataPatch = function() {
  addMoreDataPatchPairs();
  return removeDataPatchPairs();
};

const showUploadFileName = () => $(':file').on('fileselect', function(event, numFiles, label) {
  let log;
  const input = $(this).parents('.input-group').find(':text');

  if (numFiles > 1) {
    log = numFiles + ' files selected';
  } else {
    log = label;
  }

  if (input.length) {
    return input.val(log);
  } else if (log) {
    return alert(log);
  }
});

const fixture_config = function() {

  const data_source_id = $('#data_file_data_source_attributes_id').val();

  return $.ajax({
    url: '/data_sources/'+data_source_id+'/data_files/fixture_config',
    type: 'POST',
    data:
      $('form.assertion-ds').serialize()
  });
};

$(function() {
  InitImportMap();
  InitAssertion();
  InitDataPatch();
  InitDefaultFields();
  showUploadFileName();
  RemoveAttributeDisabled();
  // init tooltips
  $('[data-toggle="tooltip"]').tooltip();
  $('#assertion-ds-config input').on('input', () => fixture_config());
  $("#assertion-ds-config input[type=\'checkbox\']").change(() => fixture_config());

  return $('#show-data-patch-fields-btn').click(function() {
    $('#show-data-patch-fields').toggle();
    return $("#show-data-patch-fields-btn span").toggleClass('glyphicon-chevron-up glyphicon-chevron-down');
  });
});

$(document).on('change', ':file', function() {
  let numFiles;
  const input = $(this);
  if (input.get(0).files) {
    numFiles = input.get(0).files.length;
  } else {
    numFiles = 1;
  }
  const label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
  return input.trigger('fileselect', [numFiles, label]);
});
