/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const toggleVisibilityMaskInstructions = () => $('#toggle-mask-instructions-visibility').on('click', function(e) {
  const $link = $(this);
  e.preventDefault();
  $('#mask-instructions').slideToggle();
  if ($link.text() === 'Show instructions') {
    return $link.text('Hide instructions');
  } else {
    return $link.text('Show instructions');
  }
});

const InitToggling = () => toggleVisibilityMaskInstructions();

$(function() {
  InitToggling();
  return $('#omni_parse_url_mask_omni_parser_id').on('change', function(e){
    $('.parser').addClass('hidden');
    return $('*[data-parser="' + this.value + '"]').removeClass('hidden');
  });
});
