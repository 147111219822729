/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

window.$ = require('jquery');
import 'bootstrap/dist/js/bootstrap';

require("@rails/ujs").start()

import moment from 'moment'
window.moment = moment

$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');

import "chartkick/chart.js"

import 'select2'

// custom files
import './custom/admins'
import './custom/omni_parse_url_masks'
import './custom/pages'

import './pages/agent_list.js.erb'
import './pages/data_sources.js.erb'
import './pages/office.js.erb'
