/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const addMoreTelephoneOffice = function() {
  $('#add-more-telephone-office').off('click.telephone');
  return $('#add-more-telephone-office').on('click.telephone', function(e) {
    const $container = $('#telephone-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;
    const $options = ["work", "mobile"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    const $formInput2 = `<div class="form-group row row-no-padding"> \
                         <div class="col-sm-2"> \
                         <a class="btn btn-xs btn-default remove-telephone-office" data-index=` + $dataIndex + ` > \
                         <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> \
                         </a> \
                         </div> \
                         <div class="col-sm-4"> \
                         <select class="form-control input-sm" \
                         name="office[telephone_relationships_attributes][` + $dataIndex + `][description]" \
                         id="office_telephone_relationships_attributes_` + $dataIndex + `_description">\
                         ` + $string_options + `\
                         data-index=` + $dataIndex + `\
                         </select> \
                         </div> \
                         <div class="col-sm-2"> \
                         <input class="form-control input-sm" type="text" value="1" \
                         name="office[telephone_relationships_attributes][` + $dataIndex + `][telephone_attributes][country_code]" \
                         id="office_telephone_relationships_attributes_` + $dataIndex + '_telephone_attributes_country_code" data-index=' + $dataIndex + `> \
                         </div> \
                         <div class="col-sm-4"> \
                         <input class="form-control input-sm" type="text" \
                         name="office[telephone_relationships_attributes][` + $dataIndex + `][telephone_attributes][number]" \
                         id="office_telephone_relationships_attributes_` + $dataIndex + '_telephone_attributes_number" data-index=' + $dataIndex + `> \
                         </div> \
                         </div>`;
    $('#telephone-fields').append($formInput2);
    return removeFieldsOffice('telephone-office');
  });
};

// AgentList telephone remove button action
var removeFieldsOffice = function(type) {
  const typeReplaced =  type.replace("-","_");
  $(`.remove-${type}`).off("click." + typeReplaced);
  return $(`.remove-${type}`).on("click." + typeReplaced, function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    const remove_field = $(this).closest('.form-group').find(':input.hidden-remove');
    if (remove_field.attr('name') !== undefined) {
      const formGroup = $this.closest('.form-group');
      if (formGroup.find("input[id$='attributes_id']").length) {
        remove_field.val('1');
        return formGroup.hide();
      } else {
        formGroup.find('input').prop('disabled', true);
        formGroup.find('select').prop('disabled', true);
        return formGroup.hide();
      }
    } else {
      return $this.closest('.form-group').remove();
    }
  });
};

const InitTelephoneOffice = function() {
  addMoreTelephoneOffice();
  return removeFieldsOffice('telephone-office');
};

const addMoreWebPageOffice = function() {
  $('#add-more-web-page-office').off('click.web_page');
  return $('#add-more-web-page-office').on('click.web_page', function(e) {
    const $container = $('#web-page-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    const $options = ["facebook", "linkedin", "realtor", "trulia", "twitter", "zillow", "home_page", "brokerage_page", "photo", "other"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;

    const $formInput2 = `<div class="form-group row row-no-padding"> \
                         <div class="col-sm-2 text-center"> \
                         <a class="btn btn-xs btn-default remove-web-page-office" data-index=` + $dataIndex + ` > \
                         <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> \
                         </a> \
                         </div> \
                         <div class="col-sm-4"> \
                         <select class="form-control input-sm" name="office[web_page_relationships_attributes][` + $dataIndex + '][description]"' + ' id="data_source_import_map[' + $dataIndex + '][key]" placeholder="file header field name" class="form-control" data-index=' + $dataIndex + ` ">\
                         ` + $string_options + `\
                         </select> \
                         </div> \
                         <div class="col-sm-2 text-center"> \
                         <input name="office[web_page_relationships_attributes][` + $dataIndex + `][is_primary]" \
                         type="hidden" value="0"><input type="checkbox" value="1" \
                         name="office[web_page_relationships_attributes][` + $dataIndex + `][is_primary]" \
                         id="office_web_page_relationships_attributes_` + $dataIndex + `_is_primary" \
                         data-index=` + $dataIndex + `> \
                         </div> \
                         <div class="col-sm-4"> \
                         <input class="form-control input-sm" type="text" \
                         name="office[web_page_relationships_attributes][` + $dataIndex + `][web_page_attributes][loc]" \
                         id="office_web_page_relationships_attributes_` + $dataIndex + `_web_page_attributes_loc" \
                         data-index=` + $dataIndex + `> \
                         </div> \
                         </div>`;
    $('#web-page-fields').append($formInput2);
    return removeFieldsOffice('web-page-office');
  });
};

const InitWebPageOffice = function() {
  addMoreWebPageOffice();
  return removeFieldsOffice('web-page-office');
};

const addMoreEmailAddress = function() {
  $('#add-more-email-address-office').off('click.email_address');
  return $('#add-more-email-address-office').on('click.email_address', function(e) {
    const $container = $('#email-address-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    const $options = ["work"]
    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;

    const $formInput2 = `<div class="form-group row row-no-padding"> \
                         <div class="col-sm-2"> \
                         <a class="btn btn-xs btn-default remove-email-address-office" data-index=` + $dataIndex + ` > \
                         <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> \
                         </a> \
                         </div> \
                         <input type="hidden" value="` + $options[0] + `" \
                         name="office[email_address_relationships_attributes][` + $dataIndex + `][description]" \
                         id="office_email_address_relationships_attributes_` + $dataIndex + `_description" \
                         data-index=` + $dataIndex + `> \
                         <div class="col-sm-1"></div> \
                         <div class="col-sm-9"> \
                         <input class="form-control input-sm" type="text" \
                         name="office[email_address_relationships_attributes][` + $dataIndex + `][email_address_attributes][address]" \
                         id="office_email_address_relationships_attributes_` + $dataIndex + `_email_address_attributes_address" \
                         data-index=` + $dataIndex + `> \
                         </div> \
                         </div>`;
    $('#email-address-fields').append($formInput2);
    return removeFieldsOffice('email-address-office');
  });
};

const InitEmailAddressOffice = function() {
  addMoreEmailAddress();
  return removeFieldsOffice('email-address-office');
};

const findNestedTextByClass = (parent, classname) => parent.find(classname).text().trim();

const findNestedTextWithoutCommasByClass = (parent, classname) => parent.find(classname).text().trim().replace(/[,.]/g , '');

const copyDataToForm = () => // $('#copy-to-form').off 'click.row-data'
$('.copy-to-form').on('click.row-data', function(e) {
  const $this = $(this);
  const tr = $this.closest('tr');
  const form = $('#new_office');
  const brokerage_name = findNestedTextWithoutCommasByClass(tr, '.agent-brokerage-name');
  const brokerage_office_name = findNestedTextWithoutCommasByClass(tr, '.agent-office-name');
  const street_address = findNestedTextByClass(tr, '.agent-street');
  const city = findNestedTextByClass(tr, '.agent-city');
  const state = findNestedTextByClass(tr, '.agent-state');
  const postal_code = findNestedTextByClass(tr, '.agent-postal-code');
  const location_search_key = $('#location-search-key').text();
  const latitude = findNestedTextByClass(tr, '.agent-latitude');
  const longitude = findNestedTextByClass(tr, '.agent-longitude');

  $('#office_brokerage_name').val(brokerage_name);
  $('#office_brokerage_office_name').val(brokerage_office_name);
  $('#office_street_address').val(street_address);
  $('#office_city').val(city);
  $('#office_state').val(state);
  $('#office_postal_code').val(postal_code);
  $('#office_location_search_key').val(location_search_key);
  $('#office_latitude').val(latitude);
  return $('#office_longitude').val(longitude);
});

const copyOfficeIdToModal = () => $('.open-modal-button').on('click', function(e) {
  const $button = $(this);
  const officeId = $button.data()['officeId'];
  return $('#office-id-modal-field').val(officeId);
});

const copyOfficeNameToModal = () => $('.open-modal-button').on('click', function(e) {
  const $row = $(this).closest('tr');
  const $brokerageName = $row.find('.office-brokerage-name').text();
  const $brokerageOfficeName = $row.find('.office-brokerage-office-name').text();
  return $('#modal-office-name').text($brokerageName + ' ' + $brokerageOfficeName);
});

const InitCopying = function() {
  copyDataToForm();
  copyOfficeIdToModal();
  return copyOfficeNameToModal();
};

const selectAllAgentListDataCheckboxesInModal = () => $('#select-all-checkboxes-button-modal').on('click', function(e) {
  const button = $(this);
  const $buttonText = button.text();

  return $('.agent-list-data-modal-checkbox').each(function() {
    if ($buttonText === 'Select all') {
      $(this).prop('checked', true);
      return button.text('Deselect all');
    } else {
      $(this).prop('checked', false);
      return button.text('Select all');
    }
  });
});

const InitSelecting = () => selectAllAgentListDataCheckboxesInModal();

const preventFormSubmitting = () => $('#select-all-checkboxes-button-modal').on('click', e => e.preventDefault());

const preventFormSubmittingWithoutCheckedCheckbox = () => $('#submit-modal-button').on('click', function(e) {
  let $anyChecked = false;
  $('.agent-list-data-modal-checkbox').each(function() {
    if ($(this).is(":checked")) {
      return $anyChecked = true;
    }
  });

  if (!$anyChecked) {
    e.preventDefault();
    return $('#modal-error-messages').show();
  }
});

const InitPreventing = function() {
  preventFormSubmitting();
  return preventFormSubmittingWithoutCheckedCheckbox();
};

const toggleVisibilityOfficeInstructions = () => $('#toggle-office-instructions-visibility').on('click', function(e) {
  const $link = $(this);
  e.preventDefault();
  $('#office-instructions').slideToggle();
  if ($link.text() === 'Show instructions') {
    return $link.text('Hide instructions');
  } else {
    return $link.text('Show instructions');
  }
});

const InitToggling = () => toggleVisibilityOfficeInstructions();

$(function() {
  InitTelephoneOffice();
  InitWebPageOffice();
  InitEmailAddressOffice();
  InitCopying();
  InitToggling();
  InitSelecting();
  InitPreventing();

  $('a[disabled=disabled]').click(function(event) {
    event.preventDefault();
    // Prevent link from following its href
  });
});
