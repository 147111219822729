/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const addMoreTelephone = function() {
  $('#add-more-telephone').off('click.telephone');
  return $('#add-more-telephone').on('click.telephone', function(e) {
    const $container = $('#telephone-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;
    const $options = ["work", "mobile"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    const $formInput2 = `<div class="form-group row"> \
                          <div class="col-sm-1"> \
                          <a class="btn btn-xs btn-default remove-telephone" data-index=` + $dataIndex + ` >Remove</a> \
                          </div> \
                          <div class="col-sm-2"> \
                          <select class="form-control" \
                          name="agent_list[telephone_relationships_attributes][` + $dataIndex + `][description]" \
                          id="agent_list_telephone_relationships_attributes_` + $dataIndex + `_description">\
                          ` + $string_options + `\
                          data-index=` + $dataIndex + `\
                          </select> \
                          </div> \
                          <div class="col-sm-2"> \
                          <input class="form-control" type="text" value="1" \
                          name="agent_list[telephone_relationships_attributes][` + $dataIndex + `][telephone_attributes][country_code]" \
                          id="agent_list_telephone_relationships_attributes_` + $dataIndex + '_telephone_attributes_country_code" data-index=' + $dataIndex + `> \
                          </div> \
                          <div class="col-sm-3"> \
                          <input class="form-control" type="text" \
                          name="agent_list[telephone_relationships_attributes][` + $dataIndex + `][telephone_attributes][number]" \
                          id="agent_list_telephone_relationships_attributes_` + $dataIndex + '_telephone_attributes_number" data-index=' + $dataIndex + `> \
                          </div> \
                          </div>`;
    $('#telephone-fields').append($formInput2);
    return removeFields('telephone');
  });
};

// AgentList telephone remove button action
var removeFields = function(type) {
  const typeReplaced =  type.replace("-","_");
  $(`.remove-${type}`).off("click." + typeReplaced);
  return $(`.remove-${type}`).on("click." + typeReplaced, function(e) {
    const $this = $(this);
    const $dataIndex = $this.attr('data-index');
    const remove_field = $(this).closest('.form-group').find(':input.hidden-remove');
    if (remove_field.attr('name') !== undefined) {
      const formGroup = $this.closest('.form-group');
      if (formGroup.find("input[id$='attributes_id']").length) {
        remove_field.val('1');
        return formGroup.hide();
      } else {
        formGroup.find('input').prop('disabled', true);
        formGroup.find('select').prop('disabled', true);
        return formGroup.hide();
      }
    } else {
      return $this.closest('.form-group').remove();
    }
  });
};

const InitTelephone = function() {
  addMoreTelephone();
  return removeFields('telephone');
};

const addMoreWebPage = function() {
  $('#add-more-web-page').off('click.web_page');
  return $('#add-more-web-page').on('click.web_page', function(e) {
    const $container = $('#web-page-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    const $options = ["facebook", "linkedin", "realtor", "trulia", "twitter", "zillow", "home_page", "brokerage_page", "photo", "other"]

    let $string_options = '';
    $options.forEach(function(element) {
      $string_options += '<option value="' + element + '">' + element + '</option>';
    });

    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;

    const $formInput2 = `<div class="form-group row"> \
                        <div class="col-sm-1"> \
                        <a class="btn btn-xs btn-default remove-web-page" data-index=` + $dataIndex + ` >Remove</a> \
                        </div> \
                        <div class="col-sm-2"> \
                        <select class="form-control" name="agent_list[web_page_relationships_attributes][` + $dataIndex + '][description]"' + ' id="data_source_import_map[' + $dataIndex + '][key]" placeholder="file header field name" class="form-control" data-index=' + $dataIndex + ` ">\
                        ` + $string_options + `\
                        </select> \
                        </div> \
                        <div class="col-sm-2"> \
                        <input name="agent_list[web_page_relationships_attributes][` + $dataIndex + `][is_primary]" \
                        type="hidden" value="0"><input type="checkbox" value="1" \
                        name="agent_list[web_page_relationships_attributes][` + $dataIndex + `][is_primary]" \
                        id="agent_list_web_page_relationships_attributes_` + $dataIndex + `_is_primary" \
                        data-index=` + $dataIndex + `> \
                        <label for="agent_list_web_page_relationships_attributes_` + $dataIndex + `_is_primary">Is primary</label> \
                        </div> \
                        <div class="col-sm-6"> \
                        <input class="form-control" type="text" \
                        name="agent_list[web_page_relationships_attributes][` + $dataIndex + `][web_page_attributes][loc]" \
                        id="agent_list_web_page_relationships_attributes_` + $dataIndex + `_web_page_attributes_loc" \
                        data-index=` + $dataIndex + `> \
                        </div> \
                        </div>`;
    $('#web-page-fields').append($formInput2);
    return removeFields('web-page');
  });
};

const InitWebPage = function() {
  addMoreWebPage();
  return removeFields('web-page');
};

const addMoreEmailAddress = function() {
  $('#add-more-email-address').off('click.email_address');
  return $('#add-more-email-address').on('click.email_address', function(e) {
    const $container = $('#email-address-fields');
    let $dataIndex = $container.find('.form-control').last().attr('data-index');
    const $options = ["work"]
    if ($dataIndex === undefined) {
      $dataIndex = 0;
    }
    $dataIndex = parseInt($dataIndex) + 1;

    const $formInput2 = `<div class="form-group row"> \
                        <div class="col-sm-1"> \
                        <a class="btn btn-xs btn-default remove-email-address" data-index=` + $dataIndex + ` >Remove</a> \
                        </div> \
                        <input type="hidden" value="` + $options[0] + `" \
                        name="agent_list[email_address_relationships_attributes][` + $dataIndex + `][description]" \
                        id="agent_list_email_address_relationships_attributes_` + $dataIndex + `_description" \
                        data-index=` + $dataIndex + `> \
                        <div class="col-sm-4"> \
                        <input class="form-control" type="text" \
                        name="agent_list[email_address_relationships_attributes][` + $dataIndex + `][email_address_attributes][address]" \
                        id="agent_list_email_address_relationships_attributes_` + $dataIndex + `_email_address_attributes_address" \
                        data-index=` + $dataIndex + `> \
                        </div> \
                        </div>`;
    $('#email-address-fields').append($formInput2);
    return removeFields('email-address');
  });
};

const InitEmailAddress = function() {
  addMoreEmailAddress();
  return removeFields('email-address');
};

$(function() {
  InitTelephone();
  InitWebPage();
  return InitEmailAddress();
});
